var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "content-block",
    { attrs: { title: "Create Event" } },
    [
      _c(
        "el-form",
        { attrs: { "label-width": "160px" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Event Type" } },
            [
              _c(
                "el-select",
                {
                  model: {
                    value: _vm.selectedType,
                    callback: function ($$v) {
                      _vm.selectedType = $$v
                    },
                    expression: "selectedType",
                  },
                },
                _vm._l(_vm.availableTypes, function (type) {
                  return _c(
                    "el-option",
                    {
                      key: type.id,
                      attrs: { value: type.id, label: type.label },
                    },
                    [
                      _c("div", { staticClass: "event-type-option" }, [
                        _c("div", {
                          staticClass: "color-preview",
                          style: `background-color: ${type.color}`,
                        }),
                        _c("span", [_vm._v(_vm._s(type.label))]),
                      ]),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm.showProject
            ? _c(
                "el-form-item",
                { attrs: { label: "Project" } },
                [
                  _c("project-dropdown", {
                    model: {
                      value: _vm.selectedProjectId,
                      callback: function ($$v) {
                        _vm.selectedProjectId = $$v
                      },
                      expression: "selectedProjectId",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "Content" } },
            [
              _c("el-input", {
                attrs: { maxlength: "70", "show-word-limit": "" },
                model: {
                  value: _vm.content,
                  callback: function ($$v) {
                    _vm.content = $$v
                  },
                  expression: "content",
                },
              }),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { md: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.showDateRange ? "Start Date" : "Date",
                      },
                    },
                    [
                      _c("el-date-picker", {
                        on: { change: _vm.validateEndDate },
                        model: {
                          value: _vm.startDate,
                          callback: function ($$v) {
                            _vm.startDate = $$v
                          },
                          expression: "startDate",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.showDateRange
                ? _c(
                    "el-col",
                    { attrs: { md: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "End Date" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              "picker-options": {
                                disabledDate: _vm.isInvalidEndDate,
                              },
                              placeholder: _vm.formattedStartDate,
                              "default-value": _vm.startDate,
                            },
                            model: {
                              value: _vm.endDate,
                              callback: function ($$v) {
                                _vm.endDate = $$v
                              },
                              expression: "endDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("el-switch", {
                attrs: {
                  "active-text": "External",
                  "inactive-text": "Internal",
                },
                model: {
                  value: _vm.external,
                  callback: function ($$v) {
                    _vm.external = $$v
                  },
                  expression: "external",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _vm.event
                ? _c(
                    "div",
                    { staticClass: "update-buttons" },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { width: "200px" },
                          attrs: { disabled: _vm.invalid },
                          on: { click: _vm.updateEvent },
                        },
                        [_vm._v(" Save Event ")]
                      ),
                      _c(
                        "el-popconfirm",
                        {
                          attrs: {
                            "confirm-button-text": "OK",
                            "cancel-button-text": "No, thanks",
                            icon: "el-icon-info",
                            "icon-color": "red",
                            title: "Are you sure you want to delete this?",
                          },
                          on: { confirm: _vm.deleteEvent },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: { width: "200px" },
                              attrs: {
                                slot: "reference",
                                type: "danger",
                                plain: "",
                              },
                              slot: "reference",
                            },
                            [_vm._v(" Delete Event ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "el-button",
                    {
                      staticStyle: { width: "200px" },
                      attrs: { disabled: _vm.invalid },
                      on: { click: _vm.addEvent },
                    },
                    [_vm._v(" Add Event ")]
                  ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }